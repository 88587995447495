<template>
  <div class="body">
    <!-- 头部 -->
    <top></top>
    <div id="short_video_service">
      <div class="short_top">
        <!-- 轮播图 外层 -->
        <div class="swiper_wrap">
          <el-carousel
            :interval="4000"
            @change="swiperVideo"
            type="card"
            height="400px"
            class="swiper_list"
            indicator-position="none"
            :autoplay="false"
          >
            <el-carousel-item
              class="swiper_item"
              v-for="(item, index) in swiperList"
              :key="item.id"
              style="width: 800px"
            >
              <div class="video" @click="stopPlay(index)">
                <video
                  width="800"
                  height="450"
                  :src="item.img"
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
                <div
                  v-show="ifPlay || swiperCurrent != index"
                  class="shade_play"
                  @click="cutPlay(item)"
                >
                  <span>
                    <img src="../../static/img/play.png" />
                  </span>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 短视频分类 -->
        <div class="classify_video">
          <div class="title">
            <div class="img_box">
              <img src="../../static/img/television.png" />
            </div>
            <p>短视频分类</p>
          </div>
          <!-- 分段器 -->
          <div class="classify_sub">
            <ul>
              <img
                class="bg_img white_bg"
                src="../../static/img/white_bg.png"
              />
              <li
                v-for="(v, i) in classifyList"
                :key="v.id"
                :class="classifyActive == i ? 'active' : ''"
                @click="classifyToggle(i)"
              >
                <i v-show="i == 0" class="iconfont">&#xe644;</i>
                <i v-show="i == 1" class="iconfont">&#xe601;</i>
                <i v-show="i == 2" class="iconfont">&#xe622;</i>
                <div class="item_content">
                  <p>{{ v.title }}</p>
                  <div>{{ v.price }}元起/条</div>
                </div>
              </li>
            </ul>
            <img class="bg_img red_dot" src="../../static/img/red_dot.png" />
            <img class="bg_img gray_dot" src="../../static/img/gray_dot.png" />
          </div>
          <!-- 分类内容 -->
          <div class="classify_content">
            <div class="content_left">
              <li
                v-for="(v, i) in classifyList[classifyActive].children"
                :key="i"
                @click="classifyContentToggle(i)"
                :class="classifyContentActive == i ? 'current' : ''"
              >
                <img src="../../static/img/douyin.png" />
                <div class="content_">
                  <div class="exhibitin">{{ v.title }}</div>
                  <p>{{ v.description }}</p>
                </div>
              </li>
            </div>
            <div
              class="content_right"
              v-for="(v, i) in classifyList[classifyActive].children"
              v-if="classifyContentActive == i"
              :key="i"
            >
              <img src="../../static/img/diannao.png" alt="" />
              <div class="box_video" @click="macPlayStop(item)">
                <video
                  class="mac_video"
                  width="498"
                  height="317"
                  :src="v.video"
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
                <div class="mac_play" v-show="macPlayShow" @click="macPlay">
                  <span>
                    <img src="../../static/img/play.png" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 图片换行 -->
        <div
          class="image_hr"
          @click="skipUrl('Customization')"
          style="cursor: pointer;"
        >
          <img src="../../static/img/hr.png" />
        </div>
        <!-- 拍摄花絮 -->
        <div class="shooting_titbits">
          <div class="title">
            <div class="img_box">
              <img src="../../static/img/television.png" />
            </div>
            <p>拍摄花絮</p>
          </div>
          <ul>
            <li v-for="(v, i) in imgList" :key="i">
              <div class="img_box">
                <!-- <img src="../../static/img/huaxu.png"> -->
                <img :src="v.thumb" />
              </div>
              <p>{{v.title}}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 客户评价 -->
      <div class="evaluate">
        <div class="evaluate_box">
          <div class="evaluate_box_top">
            <p>客户评价</p>
            <span>Customer evaluation</span>
          </div>
          <div class="swiper_ul">
            <div class="prev_page page_item">
              <span @click="prev"><b>&lt;</b></span>
            </div>
            <el-carousel
              :interval="5000"
              arrow="always"
              height="222px"
              class="swiper_content"
            >
              <el-carousel-item
                v-for="item in evaluate"
                :key="item.title"
                class="swiper_item"
              >
                <li>
                  <span>{{ item.title }}</span>
                  <div v-html="item.content"></div>
                </li>
                <img :src="item.thumb" />
              </el-carousel-item>
            </el-carousel>
            <div class="next_page page_item">
              <span @click="next"><b>></b></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner_wrap">
        <div class="partner_box">
          <div class="title">
            <div class="img_box">
              <img src="../../static/img/television.png" />
            </div>
            <p>合作伙伴</p>
          </div>
          <ul>
            <li v-for="(v, i) in partnerList" :key="i">
              <p>
                <img class="default" :src="v.thumb" />
                <img class="checked" :src="v.thumb2" />
              </p>
            </li>
            <ol v-for="i in (7-partnerList.length%7)" :key="i+'ol'"></ol>
          </ul>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->

    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>
<script>

export default {
  data() {
    return {
      swiperCurrent: 0, // 当前轮播图视频index
      ifPlay: true, // 是否播放  是否显示播放按钮
      macPlayShow: true, // 是否显示播放按钮 电脑框
      partnerList: [], // 底部合作伙伴
      classifyActive: 0, // 分类分段西选中
      classifyContentActive: 0, // 当前分段内容选中
      swiperList: [], // 轮播图列表
      false: false,
      imgList: [],
      evaluate: [],  
      classifyList: [{children: []}]
    };
  },
  created() {
    var list = [];
    this.partnerList = [...list, ...list, ...list]

    this.getSwiperList(); // 获取轮播图
    this.getClassifyList(); // 获取短视频分类
    this.getTriviaList(); // 获取拍摄花絮
    this.getAppraiseList() // 获取客户评价数据
    this.getPartnerList(); // 获取 合作伙伴
  },
  methods: {
    getSwiperList() {
      this.$axios({
        method: "post",
        url: "/api/shopmini/wap_Dspbanner.html",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
          cid: 2
        }
      }).then(({data}) => {
        this.swiperList = data
      }).catch(function(error) {
        console.log(error);
      });
    },
    // 获取短视频分裂
    getClassifyList() {
      var data = new FormData()
      data.append('pid', 1)
      this.$axios({
        method: "post",
        url: "/api/shopmini/wap_Dspcateson.html",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data
      }).then(({data}) => {
        this.classifyList = data.data
      }).catch(function(error) {
        console.log(error);
      });
    },
    // 获取拍摄花絮
    async getTriviaList() {
      var {data} =  await this.submitAxios(5, 8)
      this.imgList = data.data
    },
    // 获取客户评价
    async getAppraiseList() {
      var {data} =  await this.submitAxios(6, 10)
      this.evaluate = data.data
    },
    // 获取合作伙伴
    async getPartnerList() {
      var {data} =  await this.submitAxios(7, 14)
      this.partnerList = data.data
    },
    // 发送表单数据
    submitAxios(cid, num) {
      var data = new FormData()
      data.append('cid', cid)
      data.append('num', num)
      return this.$axios({method: "post", url: "/api/shopmini/wap_Dsparticle.html", data})
    },
    // change 轮播图切换  切换的时候将所有视频暂停   播放当前index 的视频
    swiperVideo(e) {
      this.swiperCurrent = e;
      var video = $(".video");
      for (let i = 0; i < video.length; i++) {
        // video.children('video')[i].pause()
        $(".video")
          .children("video")
          [i].pause();
      }
      // .pause()
      this.cutPlay();
    },
    // 轮播图开始播放
    cutPlay() {
      setTimeout(() => {
        $(".video")
          .children("video")
          [this.swiperCurrent].play();
        this.ifPlay = false;
      }, 200);
      // $('.video')[index].play()
    },
    // 轮播图 暂停播放
    stopPlay(index) {
      this.swiperCurrent = index
      var video = $(".video")?.children("video")?.[this.swiperCurrent]
      
      video.pause();
      this.ifPlay = true;
    },
    // 客户评价上一页
    prev() {
      var e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      $(".el-carousel__arrow--left")[1].dispatchEvent(e);
    },
    // 客户评价下一页
    next() {
      var e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      $(".el-carousel__arrow--right")[1].dispatchEvent(e);
    },
    // 电脑框 视频播放
    macPlay() {
      setTimeout(() => {
        $(".mac_video")[0].play();
        this.macPlayShow = false;
      }, 200);
    },
    // 电脑框 视频播放
    macPlayStop() {
      $(".mac_video")[0].pause();
      this.macPlayShow = true;
    },
    classifyToggle(index) {
      this.classifyActive = index;
      this.classifyContentActive = 0;

      this.macPlayStop()
    },
    classifyContentToggle(index) {
      this.classifyContentActive = index;
      this.macPlay();
    },
    skipUrl(name) {
      this.$router.push({ name, query: {page:3} });
    }
  }
};
</script>
<style lang="less">
#short_video_service {
  margin-top: 60px;
  .short_top {
    margin: 0 auto;
    width: 1200px;
    // 轮播图组件
    .swiper_wrap {
      padding-top: 30px;
      .swiper_list {
        width: 1200px;
        text-align: center;
      }
      .is-active {
        transform: translateX(200px) scale(1) !important;
      }
      .swiper_item {
        border-radius: 8px;
        .video {
          position: relative;
          width: 100%;
          z-index: 3;
          .shade_play {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            span {
              z-index: 33;
              display: block;
              position: relative;
              width: 60px;
              height: 60px;
              left: calc(50% - 30px);
              top: calc(50% - 30px);
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    // 短视频分类
    .classify_video {
      margin-top: 30px;
      // 分段器
      .classify_sub {
        margin: 25px 0;
        height: calc(107px + 29px + 26px);
        width: 100%;
        position: relative;
        .bg_img {
          position: absolute;
        }
        .red_dot {
          left: 0;
          top: 0;
          z-index: 1;
          width: 208px;
          height: 107px;
        }
        .gray_dot {
          right: 0;
          bottom: 0;
          z-index: 1;
          width: 208px;
          height: 107px;
        }
        ul {
          position: relative;
          height: 118px;
          padding: 26px 50px 29px;
          display: flex;
          justify-content: space-between;
          li {
            display: flex;
            flex: 1;
            margin: 10px 10px;
            border-radius: 15px !important;
            cursor: pointer;
            z-index: 4;
            align-items: center;
            justify-content: center;
            i {
              display: block;
              font-size: 40px;
              color: #f6295a;
            }
            .item_content {
              z-index: 5;
              padding-left: 20px;
              font-size: 28px;
              font-weight: 400;
              color: #f6295a;
              line-height: 28px;
              div {
                padding-top: 15px;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
              }
            }
            &:hover {
              background: #f6295a99;
              div,
              p,
              i {
                color: #fff;
              }
            }
          }
          .active {
            background: #f6295a !important;
            div,
            p,
            i {
              color: #fff;
            }
          }
          .white_bg {
            position: absolute;
            z-index: 3;
            width: calc(100% - 100px);
            left: 50px;
            right: 50px;
          }
        }
      }
      // 内容
      .classify_content {
        display: flex;
        justify-content: space-between;
        .content_left {
          width: 40%;
          li {
            display: flex;
            cursor: pointer;
            margin-top: 10px;
            border-radius: 10px !important;
            padding: 30px 40px;
            position: relative;
            img {
              width: 40px;
              height: 40px;
            }
            .content_ {
              padding-left: 26px;
              .exhibitin {
                font-size: 20px;
                line-height: 26px;
              }
              p {
                margin-top: 13px;
                padding-right: 20px;
                font-size: 15px;
                line-height: 22px;
              }
            }
          }
          .current {
            top: -3px;
            left: -3px;
            box-shadow: 0px 0px 4px 4px #f6295a14;
          }
        }
        .content_right {
          margin: 40px;
          width: 649px;
          height: 375px;
          position: relative;
          img {
            position: absolute;
            z-index: 99;
            width: 649px;
            height: 375px;
          }
          .box_video {
            cursor: pointer;
            background: #151515;
            width: 500px;
            height: 317px;
            z-index: 99;
            margin-top: 20px;
            margin-left: 75px;
            position: relative;
            .mac_play {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              top: 0;
              span {
                z-index: 33;
                display: block;
                position: relative;
                width: 40px;
                height: 40px;
                left: calc(50% - 20px);
                top: calc(50% - 20px);
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .image_hr {
      background: #e1e1e1;
      height: 80px;
      margin: 30px 0;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 拍摄花絮
    .shooting_titbits {
      width: 100%;
      ul {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          cursor: pointer;
          position: relative;
          margin-top: 20px;
          width: 24%;
          .img_box {
            width: 100%;
            height: 200px;
            overflow: hidden;
            border-radius: 5px;
          }
          img {
            transition: 0.4s;
            width: 100%;
          }
          p {
            width: 100%;
            text-align: center;
            margin-top: 30px;
            padding-bottom: 10px;
          }
          &:hover {
            box-sizing: border-box;
            box-shadow: 4px 4px 17px 0px #f5f1f1;
            img {
              transform: scale(1.2);
              transition: 0.4s;
            }
            p {
              color: #f6295a;
            }
          }
        }
      }
    }
  }
  // 客户评价
  .evaluate {
    width: 100%;
    margin-top: 30px;
    background: #f6f8fa;
    .evaluate_box {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0;
      .evaluate_box_top {
        p {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: #222222;
          line-height: 31px;
        }
        span {
          display: block;
          width: 100%;
          font-size: 12px;
          text-align: center;
          color: #222222;
          line-height: 20px;
        }
      }
      .swiper_ul {
        margin-top: 55px;
        position: relative;
        height: 222px;
        display: flex;
        justify-content: space-between;
        img {
          position: absolute;
          width: 40px;
          height: 40px;
          left: calc(50% - 20px);
          z-index: 99;
          top: 0;
        }
        .page_item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          span {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border: 2px solid #666;
            border-radius: 50%;
            font-size: 20px;
            b {
              color: #666;
              font-weight: 500;
              transform: scaleY(1.7);
              position: relative;
              top: -2px;
            }
            &:hover {
              border-color: #f6295a;
              b {
                color: #f6295a;
              }
            }
          }
        }
        .prev_page {
          b {
            padding-right: 3px;
          }
        }
        .next_page {
          b {
            padding-left: 3px;
          }
        }
        .swiper_content {
          margin: 0 auto;
          width: 1020px;
          .swiper_item {
            z-index: 2;
            li {
              padding: 70px 45px;
              font-weight: 500;
              height: calc(222px - 140px);
              box-shadow: 4px 4px 17px 0px #f4f3f3;
              background: #ffffff;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              line-height: 22px;
              span {
                font-size: 18px;
                font-weight: 600;
                color: #000000;
              }
              p {
                margin-top: 10px;
                font-size: 14px;
                color: #000000;
              }
            }
          }
          .el-carousel__arrow--left {
            left: -1000px;
          }
          .el-carousel__arrow--right {
            right: -1000px;
          }
        }
      }
    }
  }
  // 合作伙伴
  .partner_wrap {
    width: 100%;
    height: 275px;
    padding: 50px 0 20px;
    .partner_box {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      ul {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        ol{
          width: 164px;
        }
        li {
          border-radius: 5px;
          cursor: pointer;
          overflow: hidden;
          margin-top: 10px;
          width: 164px;
          height: 60px;
          opacity: 1;
          p {
            position: relative;
            top: 0;
            img {
              width: 100%;
              height: 100%;
            }
            .checked {
              display: none;
              width: 70%;
              height: 70%;
              padding: 8% 15%;
              z-index: 4;
            }
          }
          &:hover {
            background: #f6295a;
            .checked {
              display: block;
            }
            .default {
              display: none;
            }
            p {
              @keyframes mymove {
                0% {
                  top: 0px;
                }
                1% {
                  top: 60px;
                }
                50% {
                  top: 60px;
                }
                100% {
                  top: 0px;
                }
              }
              animation: mymove 0.3s ease-in-out 0s 1 alternate forwards;
            }
          }
        }
      }
    }
  }
  // 标题
  .title {
    display: flex;
    .img_box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      padding-left: 10px;
      font-size: 28px;
      font-weight: 700;
      color: #222222;
      line-height: 40px;
    }
  }
}
</style>
